import React from 'react'
import { graphql } from 'gatsby'

import { Layout } from '../../components'

import * as styles from './career.module.scss'
import ReactMarkdown from 'react-markdown'
import { sanitizeContent } from '../../utilities/sanitizeContent'

export default ({ data: { careersJson } }) => {

  const { body, location, seoDescription, title } = sanitizeContent(careersJson)
  
  return (
    <Layout
      title={title}
      blocks={[]} >
      <div className={styles.container}>
        <h1>{title}</h1>
        <h2>{location}</h2>
        <div className={styles.body}>
          {body && <ReactMarkdown>{body}</ReactMarkdown>}
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
query ($id: String!) {
  careersJson(id: {eq: $id}) {
    body
    location
    seoDescription
    title
  }
}
`
